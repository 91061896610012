import logo from './logo.png';
import './App.css';

function Ttd() {
  return (
    <div className="Ttd">
      <header class="ttd-header">
        <a
          className="ttd-link"
          href="/"
          target="_blank"
        >
          <div className="ttd-logo">
            <img alt="ttd-logo" src={logo} height={50} />
            <span className="ttd-brand-name">Ten Toes Down</span>
          </div>
        </a>
      </header>

      <div class="ttd-body">
        <h2 className="ttd-gray-text">Our Mission</h2>
        <h2>To build <span className="ttd-gray-text">free, public, sustainable playgrounds</span> for communities across Africa.</h2>
        <p>
          <a
            className="ttd-button"
            href="https://donorbox.org/ten-toes-down-africa">
            <span className="ttd-red-text">&#x2764;</span> Donate
          </a>
        </p>
      </div>

      <div className="ttd-footer">
        <a
          className="ttd-gray-text ttd-button"
          href="https://us14.list-manage.com/contact-form?u=d043476c5696a08fb7cc54c92&form_id=a35bb7bce0692a2915f665827dad0f1b"
          target="_blank"
          rel="noreferrer">Contact
        </a>

        <a
          className="ttd-gray-text"
          href="https://www.instagram.com/tentoesdownafrica"
          target="_blank"
          rel="noreferrer">Instagram
        </a>
      </div>
    </div >
  );
}

export default Ttd;
