import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet } from "react-helmet";
import { Analytics } from '@vercel/analytics/react';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Analytics />
    <Helmet>
      <title>Ten Toes Down</title>
      <meta name="description" content={"To build free, public, sustainable playgrounds for communities across Africa."} />
      <meta name="keywords" content={["playgrounds", "nonprofit", "africa", "play", "ten toes down", "ten toes down africa", "donate"]} />
      <meta property="og:title" content={"Ten Toes Down"} />
      <meta property="og:description" content={"Ten Toes Down Africa is on a mission to build free, public, sustainable playgrounds for communities across Africa."} />

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Forum&display=swap" rel="stylesheet"></link>
    </Helmet>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
